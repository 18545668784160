@tailwind base;
@tailwind components;
@tailwind utilities;


/*
 Custom scrollbar from https://scrollbar.app/
*/
body {
    --sb-thumb-color: #5569ff;
    --sb-size: 6px;
  
    scrollbar-color: var(--sb-thumb-color);
}
  
body::-webkit-scrollbar {
    width: var(--sb-size);
}

body::-webkit-scrollbar-thumb {
    background: var(--sb-thumb-color);
}